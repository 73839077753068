<template>
  <v-progress-circular
    v-if="loading"
    indeterminate
    color="primary"
  ></v-progress-circular>
  <div v-if="symptomNum > 0 && !loading">
    <v-row
        v-for="(data, key, index) in symptomsListDisplay"
        :key="index"
        class="symptoms-column"
    >
      <div>
        <span style="font-size: 1em;">
          {{ makeString(key, data) }}
        </span>
      </div>
    </v-row>
    <v-row v-if="moreSymptoms">
      <strong style="font-size: 0.8em;">Achtung, es wurden weitere Symptome gemeldet!</strong>
    </v-row>
  </div>
  <div v-if="symptomNum === 0 && !loading">
  Keine Symptom-Meldungen (seit mind. 3 Tagen)
  </div>
</template>
  
<style>
.custom-avatar {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  /* Makes the avatar circular */
  overflow: hidden;
  /* Ensures content doesn't overflow */
}

.centered-btn {
  display: flex;
  align-items: center;
  /* Vertically center content */
  /* Optional: Add padding or margin as needed */
}

.centered-text {
  margin-left: 8px;
  align-items: center;
  /* Adjust spacing between avatar and text as needed */
  /* Optional: Add additional styles to the text */
}

.symptoms-column {
  min-width: 300px;
  white-space: nowrap;
}
</style>

<script>
import helpers from "../helpers/helpers";
  
export default {
  name: "SymptomsTable",

  props: {
      patient_id: String
  },

  data: () => ({
    symptomsList: [],
    symptomsListDisplay: [],
    toggle_multiple: [],
    moreSymptoms: false,
    symptomNum: 0,
    loading: true,
    mapping: {
      fall: { code: "1912002", color: "yellow-accent-2", display: "Sturz" },
      sleep: {
        code: "39898005",
        color: "light-blue-accent-1",
        display: "Schlafstörung",
      },
      digestion: {
        code: "300390002",
        color: "light-green",
        display: "Verdauungsstörung",
      },
      mobility: {
        code: "82971005",
        color: "blue-darken-4",
        display: "Mobilitätsstörungen",
      },
      tremor: { code: "26079004", color: "grey", display: "Tremor" },
      hospital: {
        code: "32485007",
        color: "purple",
        display: "Hospitalisierung",
      },
      acute: { code: "49634009", color: "pink", display: "akute Beschwerden" },
    },
    showAll: false,
  }),

  methods: {
    getSymptoms: async function () {
      return new Promise((resolve, reject) => {
        // Simulate an asynchronous operation (e.g., fetching data)
        this.$axios
          .get("/get_symptoms_threedays/", {
            params: {
              patient_id: this.patient_id,
            },
          })
          .then(
            (response) => {
              this.symptomsList = [];
              if (helpers.isEmpty(response.data)) {
                return;
              }
              response.data.forEach((element) => {
                this.symptomsList.push(element.resource);
              });
              // removing all duplictates before sorting them ascending
              this.removeDuplicates();
              this.symptomsList = Object.values(this.symptomsList).sort(
                function (a, b) {
                  return (
                    new Date(b.effectiveDateTime) -
                    new Date(a.effectiveDateTime)
                  );
                }
              );
            },
            (error) => {
              console.log(error);
              reject(error);
            }
          )
          .then(() => {
            // if limit is set then slice the first ten elements (per default limited, if showAll is set true
            // the filterList function is called beforehand which calls this function again and then the list will be sliced)

            // copy into temp to keep all the original elements stored in symptomsList
            let curr = new Date()
            curr.setDate(curr.getDate()-3)
            let temp = []
            this.symptomsList.filter((el) => {
              if (new Date(el.effectiveDateTime).getTime() > curr.getTime()) {
                  console.log(el)
                  temp.push(el)
              }
            })
            console.log(temp)
            //if (this.symptomsList.length >= 10 && !this.showAll) {
              //temp = temp.slice(0, 10);
            //}
            const groupedData = temp.reduce((acc, item) => {
              // Extract the date portion without the time
              const dateWithoutTime = item.effectiveDateTime.split("T")[0];

              // Check if the date exists in the accumulator
              if (!acc[dateWithoutTime]) {
                acc[dateWithoutTime] = [];
              }

              // Push the item to the corresponding date
              acc[dateWithoutTime].push(item);

              return acc;
            }, {});

            if (groupedData) {
              this.symptomsListDisplay = groupedData;
              let max = 0
              for (const [k, v] of Object.entries(this.symptomsListDisplay)) {
                if (max < 3) {
                  if (v.length > 3) {
                    this.symptomsListDisplay[k] = this.symptomsListDisplay[k].slice(0, 3)
                    this.moreSymptoms = true
                  } else {
                    this.symptomsListDisplay[k] = this.symptomsListDisplay[k].slice(0, v.length)
                  }
                  max = max + v.length
                } else {
                  delete this.symptomsListDisplay[k]
                  this.moreSymptoms = true
                }
              }
              this.symptomNum = max
            }
            console.log(this.symptomsListDisplay)
            console.log(this.symptomsListDisplay.length)
            resolve(this.symptomsList);
            this.loading = false
          });
      });
    },

    JSONStringToObject: function (json_str) {
      // if first and last chars ain't curly braces (which would indicate a json string), adjust the string
      // so that it becomes a json string
      const regex = /^{.*}$/;
      if (regex.test(json_str)) {
        return JSON.parse(json_str);
      } else {
        let str = '{"Grund": ';
        return JSON.parse(str.concat('"', json_str, '"}'));
      }
    },

    checkIfDate: function (test_var) {
      return isNaN(Date.parse(test_var)) ? false : true;
    },

    checkIfArray: function (test_var) {
      return Array.isArray(test_var);
    },

    convertToGermanDate: function (date) {
      return date
        .toLocaleTimeString("de", { timeZone: "Europe/Berlin" })
        .slice(0, 5);
    },

    removeDuplicates: function () {
      let temp = []
      this.symptomsList.forEach((el) => {
        if (!temp.find((c) => c.effectiveDateTime === el.effectiveDateTime)) {
          temp.push(el)
        }
      })
      this.symptomsList = temp
    },

    makeString: function (key, data) {
      let str = helpers.formatDateStringToGermanDate(key) + ': '
      for(let i = 0; i < data.length; i++) {
        str =  str + Object.values(this.mapping).find(
                (item) => item.code == data[i].code.coding[0].code
                ).display
        if (i !== (data.length-1)) {
          str = str + ', '
        }
      }
      return str
    }
  },
  mounted() {
    this.getSymptoms();
  },
};
</script>
